<template>
  <v-container class="centered">
    <v-row justify="center">
      <v-img src="@/assets/icon/construction.png" max-width="150"></v-img>
    </v-row>
    <v-row justify="center" class="mt-8 text-center">
      <h4>
        We know, we know, this page is under construction. <br />
        New features will be here soon!
      </h4>
    </v-row>
  </v-container>
</template>

<script>
export default {}
</script>

<style>
.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
